<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Uploader from "@/components/Materials/uploader";
import errorHandler from '@/helpers/errorHandler'

/**
 * Starter component
 */
export default {
  page: {
    title: "Matertial Form",
  },
  data() {
    return {
      title: "Materials",
      items: [
        {
          text: "Work center",
          href: "/home",
        },
        {
          text: "Materials",
          href: "/materials",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      form:{
        chapter_id: null,
      },
      year_id: null,
      chapterError: null,
      yearError: null,

    };
  },
  components: {
    Layout,
    PageHeader,
    Uploader,
  },
  computed:{
    chapters(){
      return this.$store.getters['option/chapters'];
    },
    years(){
      return this.$store.getters['option/years'];
    },
  },
  methods:{
    validate(){
      if(this.form.chapter_id){
        this.chapterError = true;
      }else{
        this.chapterError = false;
      }
      if(this.year_id){
        this.yearError = true;
      }else{
        this.yearError = false;
      }
      return this.yearError && this.chapterError;
    },
    handleSubmit() {
      this.submit = true;
      if(this.validate()){
        this.$router.push(`/materials/upload/${this.form.chapter_id}`)
      }
    },
    async clearSelectError5(){
      this.chapterError = null;
    },
    async clearSelectError4(){
      this.yearError = null;
      await this.$store.dispatch('option/getYearChapterOption',this.year_id).then( ()=>{}).catch( err => {
        errorHandler.methods.errorMessage(err);
      }); 
    },

  },
  async created(){
    await this.$store.dispatch('option/getYearOption').then( ()=>{}).catch( err => {
        errorHandler.methods.errorMessage(err);
      });
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2>Upload Materials</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6" >
                      <b-form-group label="Stage:" label-for="input-C" >
                        <b-form-select v-model="year_id" :options="this.years" :state="yearError" @change="clearSelectError4"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="year_id && this.chapters.length">
                      <b-form-group label="Chapter:" label-for="input-C" >
                        <b-form-select v-model="form.chapter_id" :options="this.chapters" :state="chapterError" @change="clearSelectError5"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                </div>
                <!-- <Uploader v-if="form.chapter_id && form.lock != null && form.lesson_id" :lock="form.lock" :chapter_id="form.chapter_id" :lesson_id="form.lesson_id"/> -->
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit">Next</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>